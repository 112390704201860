import { DeepPartial } from '@empiriecom/module-components/utils/interfaces';
import deepmerge from '@/src/utils/deepmergeconfig';
import { tenantConfig } from '@empiriecom/mybuy-components/config/ottoversandat';
import defaultConfig from './default';
import { FragmentConfig } from './types';

const ottoversandatconfig: DeepPartial<FragmentConfig> = {
  ...tenantConfig,
  locales: ['de-AT'],
  l10n: {
    'de-AT': ['base-de', 'base-de-du', 'ottoversandat-de'],
  },
  companyId: 'ottoversandat',
  tracking: {
    gtmId: 'GTM-5J9J459',
    soastaId: 'TWCKT-SC956-AY2DD-K9G3Y-24R2M',
  },
  show: {
    ...tenantConfig.show,
    ibanBeforeBic: true,
  },
  personalData: {
    supressEmailChange: true,
  },
  lounge: {
    banner: (language: string, device: string) =>
      `https://bilder.ottoversand.at/empiriecom/io/ottoversandat/${language}/lounge_banner_${device}.jpg`,
    premiumContentSubscription: (language: string) =>
      `https://fragment-content-external.ottoversandat.empirie.cloud/mein-konto/premium-subscription-${language}`,
    premiumContentDummy: (language: string) =>
      `https://fragment-content-external.ottoversandat.empirie.cloud/mein-konto/premium-dummy-${language}`,
    premiumOnTop: true,
  },
  images: {
    logoSmall: {
      default: 'https://bilder.baur.de/i/empiriecom/otto_logo_small.png',
    },
  },
  flexikonto: {
    enabled: true,
    externalUrls: {
      faq: 'https://fragment-content-external.ottoversandat.empirie.cloud/de/mein-konto/mein-flexikonto',
      paymentPause: {
        default: '/service-hilfe/service/bezahlung-und-finanzierung/flexikonto-zahlpause',
      },
      paymentProtection: {
        default: '/service-hilfe/service/bezahlung-und-finanzierung/flexikonto-zahlschutz',
      },
    },
  },
  chatBot: {
    enabled: true,
    key: 'c7b6395b64e54d9885a74f886393f0957c39acb6e40048a390beeffcf05e415d',
    visibility: {
      main: true,
      button: true,
      notifications: false,
    },
  },
};

export default deepmerge(defaultConfig, ottoversandatconfig) as FragmentConfig;
